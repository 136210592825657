import {imagePath, rtcProps} from "../../core/constants";
import React, {useEffect, useState} from "react";
import AgoraRTC from "agora-rtc-sdk-ng";

const agoraEngine = AgoraRTC.createClient({mode: "live", codec: "vp8"});
const Stream = (props: any) => {
  const [isLoadingHost, setIsLoadingHost] = useState(true);
  const [isLeftHost, setIsLeftHost] = useState(false);
  const [isSpeaker, setIsSpeaker] = useState(true);

  const [remoteAudioTrack, setRemoteAudioTrack] = useState({} as any);

  useEffect(() => {
    startBasicCall().catch((e) => console.log('e', e));
  }, [])
  let channelParameters: any = {
    remoteAudioTrack: null,
    remoteVideoTrack: null
  };

  const startBasicCall = async () => {
    setIsLoadingHost(true);
    setIsLeftHost(false);
    await agoraEngine.setClientRole('audience');
    await agoraEngine.join(rtcProps.appId, props.channelName, props.token, 0);

    agoraEngine.on("user-published", async (user, mediaType) => {
      await agoraEngine.subscribe(user, mediaType);
      setIsLoadingHost(false);
      setIsLeftHost(false);
      setTimeout(() => {
        const remotePlayerContainer = document.getElementById("streamView") as HTMLElement;
        // remotePlayerContainer.style.padding = "16px 5px 5px 5px";

        console.log("subscribe success", mediaType);
        if (mediaType === "video") {
          channelParameters.remoteVideoTrack = user.videoTrack;
          channelParameters.remoteAudioTrack = user.audioTrack;
          channelParameters.remoteVideoTrack?.play(remotePlayerContainer);
          channelParameters.remoteAudioTrack?.play();
           setRemoteAudioTrack(user.audioTrack);
        }
        if (mediaType === "audio") {
          channelParameters.remoteAudioTrack = user.audioTrack;
          channelParameters.remoteAudioTrack?.play();
          setRemoteAudioTrack(user.audioTrack);
        }
      }, 500);
      agoraEngine.on("user-unpublished", user => {
        console.log(user.uid + "has left the channel");
        setIsLoadingHost(false);
        setIsLeftHost(true);
      });
    });
  }


  const onMuteSpeaker = async () => {
    isSpeaker ? remoteAudioTrack?.setVolume(0) : remoteAudioTrack?.setVolume(100);
    setIsSpeaker(!isSpeaker);
  }
  const onLeaveStream = async () => {
    await agoraEngine.leave();
    props.setVideoCall(false);
  }
  return (
  <>
    <div className="col-lg-4 col-md-12">
      <div className="streamSection">
        {isLoadingHost &&
          <div className="isLoadingImg">
            <img src={imagePath.hostLoadingSrc} alt="starting soon"/>
          </div>
        }
        {(!isLoadingHost && !isLeftHost) && (
          <>
            <div className="streamView" id="streamView"></div>
            <button className="leaveStream" id={'leaveStream'} onClick={onLeaveStream}>Leave</button>
            <span className="views"><i className="fa-regular fa-eye"></i> You're an audience ({props.username}) </span>
            {/*<IconButton aria-label="replay" onClick={onMuteSpeaker}>
              <ReplayIcon fontSize="inherit" />
            </IconButton>*/}
          </>
        )}
        {
          (!isLoadingHost && isLeftHost) &&
          <div className="isLoadingImg">
            <img src={imagePath.hostLeftImageSrc} alt="host left"/>
          </div>
        }
      </div>
    </div>
  </>
)
}
export default Stream;

import React, {useEffect, useState} from 'react';
import Axios from "axios";
import {rtcProps, urls} from "./core/constants";
import {
  Box,
  FormControl,
  Grid,
  Typography,
  Container,
  Alert, Snackbar, TextField,
} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import LoginIcon from '@mui/icons-material/Login';
import Stream from "./components/stream/Stream";
import ChatRoom from "./components/chat/ChatRoom";
import EventDetails from "./components/event/EventDetails";

function App() {
  const url = new URLSearchParams(window.location.search);
  const [eventId, setEventId] = useState( url.get("event_id") || '');
  const [eventDetails, setEventDetails] = useState({} as any);

  const [username, setUsername] = useState('')
  const [isSubmitted, setSubmitted] = useState(false)
  const [isValidUsername, setIsValidUsername] = useState(false)
  const [videoCall, setVideoCall] = useState(false)

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [token, setToken] = useState('')
  const [channelName, setChannelName] = useState('')
  const [roomId, setRoomId] = useState('')
  const [hostId, setHostId] = useState('')

  const [openSnackBar, setOpenSnackBar] = useState(false);

  useEffect(() => {
    console.log('eventDetails', eventDetails);
    console.log('videoCall', videoCall);
  }, [eventDetails, videoCall])

  const validate = (username: string) => {
    // const isValid = /^(?=.{1,64}$)[a-z][a-z\d]*_?[a-z\d]+$/i.test(username);
    const isValid = /^(?=.{1,64}$)[a-zA-Z0-9-_.]+$/i.test(username);
    console.log('isValid', isValid);
    setIsValidUsername(isValid);
    setUsername(username);
  }
  const onSetVideoCall = () => {
    setSubmitted(true);
    if (username && isValidUsername) {
      setIsLoading(true);
      Axios.get(urls.eventDetail + eventId).then((response: any) => {
        console.log('eventDetails response.data', response.data.data);
        if (response.data && response.data.code === 200 && response.data.data) {
          const eData = response.data.data;
          setEventDetails({...eData});
          setRoomId(eData.chat_room_id);
          setHostId(eData.chat_username);
          Axios.post(urls.generateToken,{
            "appID" : rtcProps.appId,
            "appCertificate" : rtcProps.appCertificate,
            "channelName": eData.channel_name,
            "uid": '0',
            "role": "audience"
          }).then(async (tokenRes: any) => {
            const tokenResData = tokenRes.data;
            console.log('tokenRes', tokenResData); // rtmToken, rtcToken_UID, rtcToken_Account
            setIsLoading(false);
            await startBasicCall(eData.channel_name, tokenResData.rtcToken_UID);
          }).catch((e) => {
            console.log('error generating token', e);
            setIsLoading(false);
            setError(e);
          })
        } else {
          setIsLoading(false);
          setError("Event Detail not found");
          setOpenSnackBar(true);
        }
      }).catch((e) => {
        setIsLoading(false);
        setError(e);
      })
    }
  }

  const startBasicCall = (channel: string, token: string) => {
    setChannelName(channel);
    setToken(token);
    setVideoCall(true);
  }

  return (
    <>
      { eventId ? videoCall && eventDetails ? (
        // videoCall
        <div className="body d-flex">
          <section className="eventDetail p-0 streamingSection">
            <div className="container-fluid">
              <button className="btn btn-calcel" onClick={() => setVideoCall(false)}><i className="fa-solid fa-xmark"></i></button>
              <div className="row g-0">
                <EventDetails eventDetails={eventDetails}></EventDetails>
                <Stream channelName={channelName} token={token} username={username} setVideoCall={setVideoCall}></Stream>
                <ChatRoom roomId={roomId} hostId={hostId} username={username}></ChatRoom>
              </div>
            </div>
          </section>
        </div>
      ) : (
        // no videoCall
        <Container maxWidth={"lg"}>
          <Grid className='joinStream'>
          <Grid container direction="column" justifyContent="center" spacing={2}>
            <Grid item xs={12} container alignItems="center" justifyContent="center">
              <Box sx={{ mb: 2 }}>
                <Typography variant="subtitle1">Join Stream</Typography>
              </Box>
            </Grid>
          </Grid>
            <FormControl fullWidth>
              <TextField
                error={isSubmitted && !isValidUsername}
                id="outlined-username-helper-text"
                className={'usernameField'}
                label="Username"
                helperText={
                isSubmitted && !isValidUsername ?
                  "• Username must be 64 characters or less and cannot be empty\n" +
                  "• 26 uppercase/lowercase English letters (a-zA-Z)\n" +
                  "• 10 numbers (0-9)\n" +
                  '• Special symbol "_", "-", "."\n' +
                  '• The username is case insensitive, so "Aa" and "aa" are the same username.'
                  : ""}
                type="text"
                value={username}
                name="username"
                autoFocus={true}
                onChange={(e: any) => {
                  validate(e.target.value)
                }}
                inputProps={{}}
              />
            </FormControl>

            <Box sx={{ mt: 2 }}>

              <LoadingButton
                loading={isLoading}
                loadingPosition="start"
                startIcon={<LoginIcon />}
                variant="outlined"
                onClick={onSetVideoCall}
                fullWidth
                size="large"
                type="submit"
                color="secondary"
              >
                Join
              </LoadingButton>
            </Box>
          </Grid>

        </Container>
      ) : (
        // no event id
        <Container maxWidth={"lg"}>
          <Alert severity="error">Invalid Event ID!</Alert>
        </Container>
      )
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        key={'bottomRight'}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;

//event_35_1676612129
//event_35_1676614676

import WebIM from 'agora-chat'
import {rtcProps, styles, urls} from "../../core/constants";
import React, {useEffect, useRef, useState} from "react";
import Axios from "axios";
import ReplayIcon from '@mui/icons-material/Replay';
import {Alert, IconButton, Snackbar} from "@mui/material";
import * as _ from 'lodash';

const conn = new WebIM.connection({
  appKey: rtcProps.chatAppKey,
});

const ChatRoom = (props: any) => { // roomId, username, hostId
  const bottomRef = useRef<null | HTMLElement>(null);

  const [userId, setUserId] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [peerMessage, setPeerMessage] = useState('');
  const [messages, setMessages] = useState([] as any);

  const [snackBarError, setSnackBarError] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const [isReconnecting, setIsReconnecting] = useState(false);

  useEffect(() => {
    console.log('messages', messages);
    bottomRef.current?.scrollIntoView({behavior: 'smooth'});
  }, [messages])

  useEffect(() => {
    connectChat();
  }, [])

  conn.addEventHandler("connection&message", {
    onConnected: () => {
      console.log('onConnected')
      conn.joinChatRoom({
        roomId: props.roomId,
        message: 'StreamUI Chat'
      }).then(res => {
        setUserStatus('Connected');
        console.log('JoinedRoom');
      }).catch(e => {
        setUserStatus('Failed!');
        console.log('Error JoiningRoom', e);
      })
      setIsReconnecting(false);
    },
    onDisconnected: () => {
      try {
        conn.leaveChatRoom({roomId: props.roomId}).then(() => {
          setUserStatus('Disconnected!');
        }).catch((e) => {
          console.log('Error LeavingRoom', e);
          setUserStatus('Disconnected!');
        })
        setIsReconnecting(false);
      } catch (e) {}
    },
    onTextMessage: (message: any) => {
      setMessages([...messages, {
        id: message.id,
        text: message.msg,
        from: (message.from === props.hostId) ? 'Host' : message.from
      }]);
      setIsReconnecting(false);
      },
    onTokenWillExpire: () => {
      // setUserStatus('TokenWillExpire');
    },
    onTokenExpired: () => {
      setUserStatus('TokenExpired!');
      setSnackBarError('Token Expired. Try Reconnect!');
      setOpenSnackBar(true);
    },
    onError: (error) => {
      setSnackBarError(error.message);
      setOpenSnackBar(true);
      setIsReconnecting(false);
      console.log("on error", error);
    },
  });

  const connectChat = () => {
    setIsReconnecting(true);
    Axios.post(urls.generateChatToken,{
      username: props.username
    }).then(async (tokenRes: any) => { // userToken, username, uuid
      if (tokenRes.status === 200) {
        const tokenResData = tokenRes.data;
        await loginUser(tokenResData.username, tokenResData.userToken)
      }
      // setIsReconnecting(false);
    }).catch((e) => {
      setIsReconnecting(false);
      console.log('Error generateChatToken', e);
    });
  }
  const loginUser = async (id: string, token: string) => {
    await conn.open({
      user: id,
      agoraToken: token,
    });
    setUserId(id);
  }
  const logoutUser = () => {
    conn.close();
  }

  const onSendMessage = () => {
    if (peerMessage) {
      let option = {
        chatType: 'chatRoom', // "singleChat",
        type: "txt",
        to: props.roomId,
        msg: peerMessage
      } as any;

      conn.send(WebIM.message.create(option)).then((res) => {
        setPeerMessage('');
        setMessages([...messages, {
          id: res.serverMsgId,
          text: peerMessage,
          from: userId,
        }]);
      }).catch((e) => {
        console.log("send private text fail", e);
        setPeerMessage('');
      });
    }
  }

  const getChatList = () => {
    let content = [];
    const uniqueMessagesList = _.uniqBy(messages, 'id');
    if (uniqueMessagesList.length) {
      for (let item of (uniqueMessagesList as any)) {
        content.push(
          <li className={`mb-3 d-flex ${item.from === userId ? 'flex-row-reverse align-items-end':'flex-row  align-items-end'}`} key={item.id}>
            <div className="max-width-70 text-right">
              <div className={`card border-0 p-2 chatBox ${item.from === userId ? 'right-chat text-light':'left-chat'}`}>
                <p className="messageName">{item.from}</p>
                <div className="message">{item.text}</div>
              </div>
            </div>
          </li>
        );
      }
    } else {
      content.push(
        <li style={styles.noMessagesDiv} className="mb-3" key={'noMessagesId'}>
          <div className="max-width-70 text-center">
            <div className={`card border-0 p-1 center-chat text-light`}>
              <div className="message">No message found!</div>
            </div>
          </div>
        </li>
      )
    }
    return content;
  };
  return (
    <>
      <div className="col-lg-4 col-md-12">
        <div className="card card-chat-body border-0 w-100">
          <div className="chat-header justify-content-between align-items-center border-bottom pb-3">
            <div className="d-flex align-items-center justify-content-between">
              <div className="ms-3">
                <h6 className="mb-0">Chat</h6>
                <div id="statusUser">{userStatus}</div>
              </div>
              {(userStatus && userStatus !== 'Connected') && <div className="ms-3">
                <IconButton aria-label="replay" onClick={connectChat} disabled={isReconnecting}>
                  <ReplayIcon fontSize="inherit" />
                </IconButton>
              </div>}
            </div>
          </div>
          <ul className="chat-history list-unstyled mb-0 py-lg-5 py-md-4 py-3 flex-grow-1" id="chatUl">
            {getChatList()}
            <div ref={bottomRef as React.RefObject<HTMLDivElement>} />
          </ul>
          <div className="chat-message">
            <button className="btn btn-dark" type="button" onClick={onSendMessage}>Send</button>
            <textarea required className="form-control" placeholder="Enter text here..." value={peerMessage} onChange={(e) => {
              setPeerMessage(e.target.value)
            }}></textarea>
          </div>
        </div>
      </div>

      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackBar(false)}
        key={'bottomRight'}
      >
        <Alert onClose={() => setOpenSnackBar(false)} severity="error" sx={{ width: '100%' }}>
          {snackBarError}
        </Alert>
      </Snackbar>
    </>
  )
}
export default ChatRoom;
